import { useCallback, useEffect, useState } from "react";
import Container, { Title } from "../components/Container";
import Menu from "../components/Menu";
import { Button, Input, PhoneNumber } from "../components/tools/formTools";
import { useSearchParams } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import Modal from "../components/Modal";
import freader from "../components/tools/formReader";
import { useDispatch } from "react-redux";
import { post } from "../store/action/request";
import { addNotification } from "../store/action/notification";

const Home = ()=>{
    const [page, setPage] = useState("home");
    const [search] = useSearchParams();
    const [open, setOpen] = useState(false);
    const [number, setNumber] = useState("");
    const [type, setType] = useState();
    const dispatch = useDispatch();

    const submit = useCallback(e=>{
        e.preventDefault();
        let data = freader(e.target, FORM({})[type].names);
        data["phone_number"] = number;

        dispatch(post({
            url: `benin-digital/${type}/`,
            data,
            success: ()=>{
                dispatch(addNotification({title: "Succès !!!", message: "Merci pour votre volonté de participation. Nous vous contacterons très prochainement.", type: "success"}, 5000));
                setOpen(false);
            },
            key: "submit"
        }))
    }, [type, dispatch, number])


    const scrool = useCallback(to=>{
        // if(to === "contact-us"){
        //     setContact(true);
        //     return;
        // }
        let elm = document.querySelector("."+to) || document.querySelector(".describe")
        let container = document.querySelector(".page-body")

        // console.log(elm)
        
        elm && container.scrollTo({top: elm.offsetTop - 80, behavior: 'smooth',})
    }, [])

    const onClick = useCallback(tg=>{
        const i = tg.split("to=")
        scrool(i[1])
    }, [scrool])

    useEffect(()=>{

        const to = search.get("to") || "describe";
        setPage(to);
        scrool(to);

    }, [search, scrool])

    return (
        <div className="home">
            <Menu shadow={true} page={page} onClick={onClick}/>
            <Container>
                <div className="row describe">
                    <div className="abs-layer bg1"></div>
                    <div className="abs-layer bg2"></div>
                    <div className="abs-layer gray"></div>
                    <div className="abs-layer flag-wrapper">
                        <motion.div 
                            className="flag"
                            initial={{height: "20%", rotate: "200deg", scale: .5}}
                            animate={{height: "100%", rotate: "0deg", scale: 1}}
                            transition={{duration: 2, delay: 1.3, type: "spring", }}
                        >
                            <div className="green"></div>
                            <div className="yellow"></div>
                            <div className="red"></div>
                        </motion.div>
                    </div>
                    <div className="container">
                        <motion.div 
                            className="text"
                            initial={{y: '85%'}}
                            animate={{y: "0%"}}
                            transition={{duration: 3, delay: 2, type: "spring", stiffness: 20}}
                        >
                            <Title/>
                            <p>
                                Le séminaire <i> Bénin Digital Solutions</i> est un événement phare qui mettra en lumière l'avenir numérique du Bénin. 
                                Rejoignez-nous pour une journée passionnante dédiée à l'innovation, à la technologie et à l'entrepreneuriat. 
                                Découvrez les solutions digitales "Made in Bénin" qui façonnent l'avenir et explorez les opportunités de 
                                partenariat et d'investissement dans le secteur digital.
                            </p>
                            <Button onClick={()=>setOpen(true)}>Participer</Button>
                        </motion.div>
                    </div>
                </div>

                <div className="row why">
                    <div className="bulle"></div>
                    <div className="abs-layer bio"></div>
                    <h2>Pourquoi participer ?</h2>
                    <div className="why-ans">
                        <p>
                            Participer au séminaire <strong><i>Bénin Digital Solutions</i></strong> est bien plus qu'une simple opportunité de découvrir les dernières avancées technologiques; 
                            c'est une immersion dans l'avenir numérique du Bénin.
                        </p>
                        <p>
                            Que vous soyez un <strong><i>développeur</i></strong> cherchant à élargir vos compétences, 
                            un <strong><i>entrepreneur</i></strong> à la recherche de nouvelles opportunités, un investisseur souhaitant découvrir des <strong><i>projets prometteurs</i></strong>, 
                            ou simplement un passionné de technologie désireux d'en savoir plus, cet événement offre un éventail d'avantages. 
                        </p>
                        <p>
                            
                            Plongez dans des conférences inspirantes, assistez à des <strong><i>démonstrations innovantes</i></strong>, tissez des liens stratégiques avec des experts du secteur, 
                            et explorez des opportunités de croissance économique. Rejoignez-nous pour contribuer à façonner <strong><i>l'avenir numérique</i></strong> du Bénin 
                            et pour être à la pointe de <strong><i>l'innovation</i></strong> dans ce secteur en plein essor.
                        </p>
                    </div>

                    <motion.div 
                        className="items"
                        initial="free"
                        whileInView="enter"
                        transition={{delayChildren: .5, staggerChildren: .2}}
                        viewport={{once: true}}
                    >
                        {WHY.map((v, k)=>(
                            <motion.div className="item" key={k} variants={iv}>
                                <div className="border-lt"></div>
                                <div className="border-rb"></div>
                                <h5>{v.title}</h5>
                                <p>{v.content}</p>
                            </motion.div>
                        ))}
                    </motion.div>

                    <div className="start-btn">
                        <Button onClick={()=>setOpen(true)}>Participer</Button>
                        <div className="line"></div>
                    </div>
                </div>

                <div className="row who">
                    <div className="abs-layer bg"></div>
                    <h2>Qui Peut Participer ?</h2>
                    <p>
                        Le séminaire <strong><i>Bénin Digital Solutions</i></strong> est ouvert à un large éventail de participants passionnés par la <strong><i>technologie</i></strong>, 
                        <strong><i> l'innovation</i></strong> et <strong><i>l'avenir numérique du Bénin</i></strong>. 
                    </p>

                    <motion.div 
                        className="items who-items"
                        initial="free"
                        whileInView="enter"
                        transition={{delayChildren: .5, staggerChildren: .2}}
                        viewport={{once: true}}
                    >
                        {WHO.map((v, k)=>(
                            <motion.div className="item" key={k} variants={iv}>
                                <div className="border-lt"></div>
                                <div className="border-rb"></div>
                                <div className="num">0{k+1}</div>
                                <h5>{v.title}</h5>
                                <p>{v.content}</p>

                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <div 
                                        className="open-link" onClick={()=>{setType(v.key); setOpen(true)}}
                                        style={{cursor: "pointer", borderBottom: "2px solid rgba(255,255,255, .5)"}}

                                    >
                                        Participer
                                    </div>

                                </div>
                            </motion.div>
                        ))}
                    </motion.div>

                    <div className="aims">
                        <h2>Objectifs de l’évènement</h2>

                        <div className="items">
                            {AIMS.map((v, k)=>(
                                <div className="item" key={k}>
                                    <div className="border-lt"></div>
                                    <div className="border-rb"></div>
                                    <h5>{v.title}</h5>
                                    <p>{v.content}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="objectif"></div>
                <Button onClick={()=>setOpen(true)}>Participer</Button>
                
                <Modal setShow={setOpen} show={open} onClose={()=>setType()}>


                    <AnimatePresence mode="popLayout">
                        {!type? (
                            <motion.div 
                                layout className="type-items"
                                initial={{ scale: 0.8, opacity: 0 }}
                                animate={{ scale: 1, opacity: 1 }}
                                exit={{ scale: 0.8, opacity: 0 }}
                                transition={{ type: "spring" }}
                                key="list"
                            >
                                <h3>Participer en tant que:</h3>
                                {SELECTE_WHO.map((v)=>(
                                    <motion.div 
                                        key={v.key} className="type-item"
                                        whileHover={{
                                            scaleY: 1.2,
                                            // fontWeight: 400
                                        }}
                                        whileTap={{
                                            scaleX: .98,
                                        }}
                                        onClick={()=>setType(v.key)}
                                    >
                                        {v.title}
                                    </motion.div>
                                ))}
                            </motion.div>

                        ):(
                            <motion.div 
                                layout 
                                key="form"
                                initial={{ scale: 0.8, opacity: 0 }}
                                animate={{ scale: 1, opacity: 1 }}
                                exit={{ scale: 0.8, opacity: 0 }}
                                transition={{ type: "spring" }}
                            > 

                                <form onSubmit={submit}> 
                                    {FORM({number, setNumber})[type]?.fields}
                                    <div className="btn-box">
                                        <Button onClick={()=>setType()} className="default" type="button" style={{backgroundColor: "white", color: "black"}}>Retour</Button>
                                        <Button pk="submit">Envoyer</Button>
                                    </div>
                                </form>
                            </motion.div>
                        )}
                        
                    </AnimatePresence>
                </Modal>
            </Container>
        </div>
    )
}

const iv = {
    free: {
        opacity: 0,
        x: 50,
    },
    enter:{
        opacity: 1,
        x: 0,
    }
}

const WHY = [
    {
        title: "Démonstrations Innovantes",
        content: <>Soyez témoin de démonstrations en direct de solutions digitales <strong><i>Made in Bénin</i></strong> qui révolutionnent l'industrie. De la fintech à l'agritech, explorez l'ingéniosité locale.</>
    },
    {
        title: "Conférences Inspirantes",
        content: "Écoutez des leaders du secteur partager leurs expériences et leurs idées sur les dernières tendances digitales. Découvrez comment la technologie transforme le Bénin."
    },
    {
        title: " Réseautage Stratégique",
        content: "Établissez des contacts précieux avec des entrepreneurs, des innovateurs, des investisseurs et des experts. Le séminaire offre d'innombrables occasions de réseautage."
    },
    {
        title: "Investissez dans l'Avenir Numérique du Bénin",
        content: " Explorez des opportunités d'investissement dans des startups et des projets technologiques à fort potentiel. Contribuez à façonner l'avenir numérique du pays."
    },

]

const  WHO = [
    {
        key: "dev-pro",
        title: "Développeurs et Programmeurs",
        content: " Que vous soyez un développeur expérimenté ou en devenir, le séminaire est l'endroit idéal pour découvrir les dernières tendances technologiques et partager vos connaissances."
    },
    {
        key: "sta-ent",
        title: "Startups et Entrepreneurs",
        content: "Les startups et les entrepreneurs sont au cœur de l'innovation. Venez présenter vos idées et vos produits, connectez-vous avec des investisseurs et explorez des opportunités de croissance."
    },
    {
        key: "inv",
        title: "Investisseurs",
        content: "Si vous recherchez des opportunités d'investissement dans le secteur digital béninois en plein essor, ce séminaire vous offre un accès privilégié à des projets innovants."
    },
    {
        key: "org-ins",
        title: "Organisations et Institutions",
        content: "Les organisations gouvernementales, les ONG, les associations professionnelles et toutes les autres entités engagées dans la promotion de l'innovation digitale sont encouragées à participer et à soutenir l'événement."
    },
]

const SELECTE_WHO = [
    {
        title: "Sponsor",
        key: "sponsor"
    },
    {
        "title": "Développeur ou Programmeur",
        "key": "dev-pro",
    },
    {
        "title": "Startup ou Entrepreneur",
        "key": "sta-ent",
    },
    {
        "title": "Investisseur",
        "key": "inv",
    },
    {
        "title": "Organisation ou Institution",
        "key": "org-ins",
    },
]

const AIMS = [
    {
        title: "Promouvoir l'Innovation Locale",
        content: <>Le séminaire met en lumière les innovations digitales <strong><i>Made in Bénin</i></strong>. Il offre une plateforme pour les startups locales, les développeurs et les entrepreneurs afin de présenter leurs solutions technologiques et de promouvoir l'innovation au sein du pays.</>
    },
    {
        title: "Stimuler la Créativité et l'Esprit d'Entreprise",
        content: " Le séminaire inspire la créativité et l'entrepreneuriat en présentant des cas de réussite et en encourageant les participants à explorer de nouvelles idées et opportunités dans le domaine digital."
    },
    {
        title: "Contribuer à la Croissance Économique",
        content: <>En favorisant <strong><i>l'innovation</i></strong>, <strong><i>l'entrepreneuriat</i></strong> et <strong><i>l'investissement</i></strong> dans le secteur digital, l'événement contribue à la croissance économique du Bénin en créant des emplois, en stimulant l'activité économique et en renforçant la compétitivité du pays.</>
    }

]

const FORM = ({number, setNumber})=>({
    sponsor:{
        names: ["fullName", "email", "phoneNumber"],
        fields: <>
            <Input label="Nom et Prénoms" required name="fullName" pk="full_name"/>
            <Input label="Email"  type="email" required  name="email" pk="email"/>
            <PhoneNumber label="Votre Numéro" name="phoneNumber" pk="phone_number" value={number} setValue={setNumber} />
        </>
    },
    "dev-pro": {
        names: ["fullName", "email", "phoneNumber", "competence", "experience"],
        fields : <>
            <Input label="Nom et Prénoms" required name="fullName" pk="full_name"/>
            <Input label="Email"  type="email" required  name="email" pk="email"/>
            <PhoneNumber label="Votre Numéro" name="phoneNumber" pk="phone_number" value={number} setValue={setNumber} />

            <Input 
                label="Compétences et Expertise" 
                placeholder="Veuillez décrire brièvement vos compétences en développement ou en programmation" 
                required 
                type="textarea" name="competence" pk="competence" 
                rows={3}
            />
            <Input 
                label="Expérience Professionnelle" 
                placeholder="Présentez vos expériences professionnelles liées au développement ou à la programmation" 
                // required 
                type="textarea" name="experience" pk="experience" 
                rows={3}
            />
            
        </>
    },
    "sta-ent": {
        names: [
            "fullName", "email", "phoneNumber",
            "name", "description", "aims"
        ],
        fields: <>
            <Input label="Nom de la Startup/Entreprise" required name="name" pk="name"/>
            <Input label="Nom et Prénoms du Représentant" required name="fullName" pk="full_name"/>
            <Input label="Email"  type="email" required  name="email" pk="email"/>
            <PhoneNumber label="Votre Numéro" name="phoneNumber" pk="phone_number" value={number} setValue={setNumber} />

            <Input 
                label="Description de la Startup/Entreprise" 
                placeholder="Veuillez décrire brièvement votre startup/entreprise, ses activités, ses produits/services, et son stade de développement" 
                // required 
                type="textarea" name="description" pk="description" 
                rows={3}
            />
            <Input 
                label="Objectifs de Participation" 
                placeholder="Indiquez ce que vous espérez accomplir en participant au séminaire" 
                // required 
                type="textarea" name="aims" pk="aims" 
                rows={3}
            />
        </>
    },
    "inv": {
        names: [
            "fullName", "email", "phoneNumber",
            "type", "experience"
        ],
        fields: <>
            {/* <Input label="Nom de la Startup/Entreprise" required name="fullName" pk="full_name"/> */}
            <Input label="Nom et Prénoms" required name="fullName" pk="full_name"/>
            <Input label="Email"  type="email" required  name="email" pk="email"/>
            <PhoneNumber label="Votre Numéro" name="phoneNumber" pk="phone_number" value={number} setValue={setNumber} />

            <Input 
                label="Type d'Investissement Recherché" 
                placeholder="Précisez le type d'investissement que vous recherchez (p. ex., investissement en capital-risque, investissement providentiel, etc.)" 
                // required 
                type="textarea" name="type" pk="type" 
                rows={3}
            />
            <Input 
                label="Expérience en Investissement" 
                placeholder="Décrivez votre expérience passée en tant qu'investisseur, y compris vos secteurs d'intérêt" 
                // required 
                type="textarea" name="experience" pk="experience" 
                rows={3}
            />
        </>
    },
    "org-ins": {
        names: [
            "fullName", "email", "phoneNumber",
            "name", "type", "aims"
        ],
        fields: <>
            <Input label="Nom de l'Organisation/Institution" required name="name" pk="name"/>
            <Input label="Nom et Prénoms du Représentant" required name="fullName" pk="full_name"/>
            <Input label="Email"  type="email" required  name="email" pk="email"/>
            <PhoneNumber label="Votre Numéro" name="phoneNumber" pk="phone_number" value={number} setValue={setNumber} />

            <Input 
                label="Type d'Organisation/Institution" 
                placeholder="Précisez le type d'organisation ou d'institution que vous représentez (p. ex., gouvernement, ONG, association professionnelle, etc.)." 
                // required 
                type="textarea" name="type" pk="type" 
                rows={3}
            />
            <Input 
                label="Objectifs de Participation" 
                placeholder="Indiquez les objectifs spécifiques de votre organisation/institution en participant au séminaire" 
                // required 
                type="textarea" name="aims" pk="aims" 
                rows={3}
            />
        </>
    }
})

export default Home;
