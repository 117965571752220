import 'normalize.css'
import { Provider } from 'react-redux'
import { store } from './store';
import Pages from './pages';

function App() {

  return (
    <div className="App">
			<Provider store={store}>
				<Pages/>
			</Provider>
		</div>
  )
}

export default App
