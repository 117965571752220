import Notification from "./Notification";
// import { useCallback, useState } from "react";
// import { Link, useNavigate, useSearchParams } from "react-router-dom";
// import { createPortal } from "react-dom";
// import { AnimatePresence, motion } from "framer-motion";

const Container = ({children})=>{


    return (
        <div className="container page-wrapper">

            <div className="page-body">
                <div className="page-body-container">
                    {children}
                </div>
                <div className="footer">
                    <a href="https://projetsmart.com">
                        <div className="logo"></div>
                    </a>
                    <div className="txt">
                        © Copyright 2023 | All Rights Reserved
                    </div>
                </div>
            </div>


            <Notification/>
            {/* <Lang/> */}
        </div>
    )
}

export const Title = ({style})=>{

    return (
        <h2 className="title" style={style}>
            <span>Bénin </span> <span>Digital </span> <span>Solution</span>
        </h2>
    )
}

export default Container;
