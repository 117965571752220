

import { BrowserRouter, Route, Routes } from "react-router-dom";
import "../styles/index.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { addNotification } from "../store/action/notification";
import { loadCredentials } from "../store/action/credential";
import Home from "./Home";



const Pages = ()=>{
	const {errors: {detail}} = useSelector(state=>state.alert);
	const dispatch = useDispatch();

	useEffect(()=>{
		detail && dispatch(addNotification({message: detail}, 5000));
	}, [detail, dispatch])

	useEffect(()=>{
		dispatch(loadCredentials());
	}, [dispatch])

    return (
        <BrowserRouter>
			<Routes>
				<Route path="" element={<Home/>} />

			</Routes>
		</BrowserRouter>
    )
}

export default Pages;