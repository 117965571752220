import { createPortal } from "react-dom";
import {AnimatePresence, motion} from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { forwardRef} from "react";
import { closeNotification } from "../store/action/notification";



const Notification = ()=>{
    const {contents} = useSelector(state=>state.notify);
    // const dispatch = useDispatch();


    // useEffect(()=>{
    //     setInterval(()=>{
    //         dispatch(addNotification({
    //             message: "Ceci est une notification", title: "Je te signale", setId: console.log
            
    //         },
    //         5000
    //         ));

    //     }, 5000)

    // }, [dispatch])

    return createPortal(
        <motion.div className="notifications">
            <AnimatePresence mode="popLayout">
                {contents.slice().reverse().map((item) => (
                    <Item item={item} key={item.id} />
                ))}
            </AnimatePresence>
        </motion.div>
        ,document.body
    )
}

const Item = forwardRef(({item}, ref)=>{
    const dispatch = useDispatch();

    return (
        <motion.div
            layout
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ type: "spring" }}
            className="notification"
            ref={ref}
        >

            <span 
                className="close" onClick={()=>dispatch(closeNotification(item.id))}
            >
                &times;
            </span>
            <h3>{item.title}</h3>
            <p>
                {item.message}
            </p>
        </motion.div>
    )

})

export default Notification;